import type { AxiosResponse } from "axios";
import axios from "axios";
import type { AuthenticationResponseJSON } from "nid-common";

type PostAuthenticationPasskeysRequest = {
  interactionId: string;
} & AuthenticationResponseJSON;

export type PostAuthenticationPasskeysResponse = {
  state:
    | "authenticated"
    | "login_id_required"
    | "login_id_specified"
    | "challenge_required"
    | "password_confirmed"
    | "email_confirmation_required";
  opt: string;
};

export const postAuthenticationPasskeys = (
  body: PostAuthenticationPasskeysRequest,
  signal?: AbortSignal,
): Promise<AxiosResponse<PostAuthenticationPasskeysResponse>> => {
  return axios.post(import.meta.env.VITE_API_AUTHENTICATION_PASSKEYS, body, {
    signal,
  });
};
