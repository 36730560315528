import { datadogRum } from "@datadog/browser-rum";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import { authenticationId } from "@/api/authentication-id";
import {
  defaultSignupEmailState,
  fixEmailState,
  loginEmailState,
  userFlowState,
} from "@/contexts/RequestContext";
import useNidForm from "@/hooks/nidForm";
import { useAuthenticatePasskey } from "@/hooks/useAuthenticatePasskey";
import { useAuthenticationConfirm } from "@/hooks/useAuthenticationConfirm";
import { path } from "@/routes";
import { AtlasTracking } from "nid-common";
import { useEffect, useState } from "react";

/** Form data interface. */
export interface LoginFormInputs {
  email: string;
}

/** Validation schema. */
const schema = yup.object().shape({
  email: yup.string().required("login.error.requiredEmail"),
});

export const enableAuthenticationPasskey =
  import.meta.env?.VITE_FEATURE_FLAG_AUTHENTICATE_PASSKEY === "true";

/**
 * LoginIdFeature hook
 *
 * @category React hooks
 */
export const useLoginIdFeature = (interactionId: string) => {
  const [loginEmail, setLoginEmail] = useAtom(loginEmailState);
  const setDefaultSignupEmailState = useSetAtom(defaultSignupEmailState);
  const fixEmail = useAtomValue(fixEmailState);
  const setUserFlowState = useSetAtom(userFlowState);
  const navigate = useNavigate();
  const authenticationConfirm = useAuthenticationConfirm();
  const [controller, setController] = useState<AbortController | undefined>(
    undefined,
  );
  const [isSubmittingPasskey, setIsSubmittingPasskey] = useState(false);

  const { authenticate, isConditionalMediationAvailable } =
    useAuthenticatePasskey(interactionId);

  const onSubmit = async (input: LoginFormInputs): Promise<void> => {
    try {
      const response = await authenticationId(input.email, interactionId);
      if (response.data.opt === "invalid_login_id") {
        AtlasTracking.beginSignInFailed(response.data.opt);
        throw "login.error.invalidEmail";
      }
      if (response.data.opt === "user_not_found") {
        AtlasTracking.beginSignInFailed(response.data.opt);
        setDefaultSignupEmailState(input.email);
        throw "login.error.userNotFound";
      }
      if (
        response.data.state === "login_id_specified" &&
        response.data.opt === "passkey_possession" &&
        enableAuthenticationPasskey
      ) {
        setUserFlowState("login");
        setLoginEmail(input.email);
        AtlasTracking.beginSignIn("passkey");
        navigate(path.login.passkey);
      } else if (response.data.state === "login_id_specified") {
        setUserFlowState("login");
        setLoginEmail(input.email);
        AtlasTracking.beginSignIn("password");
        navigate(path.login.password);
      } else {
        throw new Error(`Unexpected state: ${response.data.state}`);
      }
    } catch (e) {
      if (typeof e === "string") {
        throw e;
      }
      AtlasTracking.beginSignInFailed(e);
      datadogRum.addError(e);
      navigate(path.login.error);
    }
  };

  /** Form hook. */
  const {
    register,
    handleSubmit,
    apiError,
    fieldErrors,
    isSubmitting,
    watch,
    setError,
  } = useNidForm<LoginFormInputs, "login">({
    resolver: yupResolver(schema),
    onSubmit: onSubmit,
    defaultValues: {
      email: loginEmail,
    },
  });

  const email = watch("email");
  setDefaultSignupEmailState(email);
  const formHasValue = email.length > 0;

  const errorId = fieldErrors?.email?.message || apiError;

  useEffect(() => {
    // fixEmailでreturnしているのは1passwordなどのパスワードマネージャーがある場合に
    // login_hintのユーザ以外で認証できないようにするため
    if (
      !isConditionalMediationAvailable ||
      !enableAuthenticationPasskey ||
      fixEmail
    ) {
      return;
    }
    const newController = new AbortController();
    setController(newController);

    (async () => {
      const signal = newController.signal;
      try {
        const r = await authenticate({
          signal,
          autofill: true,
          onStart: () => setIsSubmittingPasskey(true),
        });

        if (r === "aborted" || r === "not_allowed") {
          return;
        }

        AtlasTracking.beginSignIn("passkey");
        if (r === "invalid_interaction") {
          AtlasTracking.verifyPasskeyFailed("conditional", r);
          navigate(path.login.error);
          return;
        }

        if (!(r instanceof Error)) {
          if (r.response.data.state === "authenticated") {
            AtlasTracking.verifyPasskey("conditional", r.id);
            await authenticationConfirm.callConfirmAndNavigateLocation(
              interactionId,
            );
          } else {
            AtlasTracking.verifyPasskeyFailed("conditional", "invalid_passkey");
            setError("email", { message: "login.error.passkey" });
          }
        } else {
          datadogRum.addError(r);
          AtlasTracking.verifyPasskeyFailed("conditional", r);
          setError("email", { message: "login.error.passkey" });
        }
      } finally {
        setIsSubmittingPasskey(false);
      }
    })();

    return () => newController.abort();
  }, [isConditionalMediationAvailable, enableAuthenticationPasskey, fixEmail]);

  const onAuthenticatePasskey = async () => {
    try {
      AtlasTracking.beginSignIn("passkey");
      setIsSubmittingPasskey(true);
      controller?.abort();
      const r = await authenticate({ autofill: false });
      if (r === "aborted" || r === "not_allowed") {
        if (r === "not_allowed") AtlasTracking.closePasskeyPrompt(r);
        else AtlasTracking.verifyPasskeyFailed(undefined, r);
        return;
      }

      if (r === "invalid_interaction") {
        AtlasTracking.verifyPasskeyFailed(undefined, r);
        navigate(path.login.error);
        return;
      }

      if (!(r instanceof Error)) {
        if (r.response.data.state === "authenticated") {
          AtlasTracking.verifyPasskey(undefined, r.id);
          await authenticationConfirm.callConfirmAndNavigateLocation(
            interactionId,
          );
        } else {
          AtlasTracking.verifyPasskeyFailed(undefined, "invalid_passkey");
          setError("email", { message: "login.error.passkey" });
        }
      } else {
        datadogRum.addError(r);
        AtlasTracking.verifyPasskeyFailed(undefined, String(r));
        setError("email", { message: "login.error.passkey" });
      }
    } finally {
      setIsSubmittingPasskey(false);
    }
  };

  return {
    form: {
      register,
      handleSubmit,
      isSubmitting,
    },
    fixEmail,
    errorId,
    formHasValue,
    isSubmittingPasskey,
    onAuthenticatePasskey,
  };
};
