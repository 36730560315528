import type React from "react";

import {
  Box,
  NikkeiIDLogo,
  NikkeiLogo,
  SkeletonText,
  Typography,
  Typography2,
} from "nikkei-ui";

type Props = {
  title: string | "loading";
  testId?: string;
  children: React.ReactNode;
};

const Footer: React.FC = () => {
  return (
    <Box as="footer" className="nid-footer w-full" data-testid="footer">
      <Box className="nid-footer-bottom">
        <Box className="nid-copyright">
          <NikkeiLogo />
          <Typography as="span" variant="h6" className="nid-copyright-text">
            No reproduction without permission.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const DialogLayout: React.FC<Props> = (props) => {
  return (
    <>
      <Box className="nid-dialog-container">
        <Box className="nid-dialog-content">
          <Box as="section" className="nid-dialog">
            <Box as="div" className="nid-item-center">
              <Typography2
                lineHeight={"180"}
                as="h1"
                aria-label="Nikkei ID"
                className="mb-2"
              >
                <NikkeiIDLogo />
              </Typography2>
              <Typography2
                as="h2"
                size={"24"}
                className={
                  props.title === "loading"
                    ? "nid-loading-title mb-16"
                    : "mb-16"
                }
                {...(props.testId && { "data-testid": props.testId })}
              >
                {props.title === "loading" ? (
                  <SkeletonText size="large" />
                ) : (
                  props.title
                )}
              </Typography2>
            </Box>
            {props.children}
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

DialogLayout.displayName = "Nid.DialogLayout";
