import {
  type PostAuthenticationPasskeysResponse,
  postAuthenticationPasskeys,
} from "@/api/postAuthenticationPasskeys";
import { postPublicKeyCredentialRequestOptions } from "@/api/publicKeyCredentialRequestOptions";
import type { AxiosResponse } from "axios";
import {
  AtlasTracking,
  parseRequestOptionsFromJSON,
  toAuthenticationResponseJson,
} from "nid-common";
import { useWebAuthn } from "nid-common/hooks/useWebAuthn";

type AuthenticateResponse =
  | { id: string; response: AxiosResponse<PostAuthenticationPasskeysResponse> }
  | "aborted"
  | "not_allowed"
  | "invalid_interaction"
  | Error;

export const useAuthenticatePasskey = (interactionId: string) => {
  const { getPublicKeyCredential, isConditionalMediationAvailable } =
    useWebAuthn();
  const authenticate = async (param: {
    signal?: AbortSignal;
    loginId?: string;
    autofill: boolean;
    onStart?: () => void;
  }): Promise<AuthenticateResponse> => {
    const { signal, loginId, autofill } = param;
    const requestOptions = await postPublicKeyCredentialRequestOptions(
      { loginId: loginId },
      signal,
    ).catch((e) => {
      return new Error(
        "PublicKeyCredentialRequestOptionsの取得に失敗しました",
        { cause: e },
      );
    });

    if (requestOptions instanceof Error) {
      return requestOptions;
    }

    const options = parseRequestOptionsFromJSON(requestOptions.data.publicKey);
    const publicKeyCredential = await getPublicKeyCredential({
      signal,
      mediation: autofill ? "conditional" : undefined,
      publicKey: options,
    }).catch((e) => {
      if (e instanceof DOMException && e.name === "AbortError") {
        return "aborted";
      }
      if (e instanceof DOMException && e.name === "NotAllowedError") {
        AtlasTracking.closePasskeyPrompt("not_allowed");
        return "not_allowed";
      }
      AtlasTracking.closePasskeyPrompt("unexpected", e);
      return new Error("PublicKeyCredentialの取得に失敗しました", {
        cause: e,
      });
    });

    param?.onStart?.();

    if (publicKeyCredential instanceof Error) {
      return publicKeyCredential;
    }

    if (
      publicKeyCredential === "aborted" ||
      publicKeyCredential === "not_allowed"
    ) {
      return publicKeyCredential;
    }

    if (publicKeyCredential === null) {
      return new Error("PublicKeyCredentialはnullになりました");
    }

    const publicKeyCredentialJSON =
      toAuthenticationResponseJson(publicKeyCredential);

    try {
      return {
        id: publicKeyCredentialJSON.id,
        response: await postAuthenticationPasskeys(
          { interactionId, ...publicKeyCredentialJSON },
          signal,
        ),
      };
    } catch {
      return "invalid_interaction";
    }
  };

  return {
    authenticate,
    isConditionalMediationAvailable,
  };
};
